import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import GoogleLogin from './GoogleLogin';
import ExcelUpload from './components/ExcelUpload';
import { AuthProvider } from './context/AuthContext';
import SharePlace from './components/SharePlace';
import AppVision from './components/AppVision';
import FeedBack from './components/FeedBack';
import ScheduleMain from './components/ScheduleMain';
import ScheduleParsing from './components/ScheduleParsing';
import ScheduleImage from './components/ScheduleImage'
import PrivacyPolicy from './components/PrivacyPolicy'
import './global-styles.css';  // 글로벌 스타일 import

const App = () => {
  return (
    <AuthProvider>
        <Routes>
          <Route path="/" element={<GoogleLogin />} />
          <Route path="/ExcelUpload" element={<ExcelUpload />} />
          <Route path="/SharePlace" element={<SharePlace />} />
          <Route path="/AppVision" element={<AppVision />} />
          <Route path="/FeedBack" element={<FeedBack />} />
          <Route path="/ScheduleMain" element={<ScheduleMain />} />
          <Route path="/ScheduleParsing" element={<ScheduleParsing />} />
          <Route path="/ScheduleImage" element={<ScheduleImage />} />
          <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
        </Routes>
    </AuthProvider>
  );
};

export default App;