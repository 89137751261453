import React from 'react';
import { Box, CssBaseline, Typography, Paper, Fade } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import NavigationBar from './NavigationBar';
import './PrivacyPolicy.css';

const theme = createTheme();

const PrivacyPolicy = () => {
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ flexGrow: 1 }}>
        <NavigationBar />
        <CssBaseline />
        <div className="privacy-policy-container gradient-background">
          <div className="privacy-policy-content">
            <Typography variant="h3" component="h1" className="privacy-policy-title">
              크루크루(crewcrew) 개인정보 처리방침
            </Typography>
            
            <Typography variant="body1" className="privacy-policy-text">
              현행 시행 일자: 2024년 08월 01일
            </Typography>
            
            <Typography variant="body1" className="privacy-policy-text">
              크루크루(이하 "회사")는 이용자의 개인정보를 중요시하며, 「개인정보 보호법」 등 관련 법령을 준수하고 있습니다. 회사는 개인정보처리방침을 통하여 이용자가 제공하는 개인정보가 어떠한 용도와 방식으로 이용되고 있으며, 개인정보보호를 위해 어떠한 조치가 취해지고 있는지 알려드립니다.
            </Typography>
            
            <Typography variant="h5" component="h2" className="privacy-policy-subtitle">
              1. 수집하는 개인정보 항목 및 수집방법
            </Typography>
            
            <Typography variant="body1" className="privacy-policy-text">
              회사는 Google 계정을 통한 로그인 서비스를 제공하고 있으며, 이 과정에서 Google로부터 다음과 같은 개인정보를 제공받아 수집합니다:
            </Typography>
            
            <Typography variant="h6" component="h3" className="privacy-policy-subtitle">
              1) Google 계정을 통해 수집하는 정보
            </Typography>
            
            <ul className="privacy-policy-list">
              <li>Google 계정 ID (고유식별자)</li>
              <li>이메일 주소</li>
              <li>이름 (성과 이름 포함)</li>
              <li>프로필 사진 URL</li>
            </ul>
            
            <Typography variant="h6" component="h3" className="privacy-policy-subtitle">
              2) 서비스 이용 과정에서 생성되는 정보
            </Typography>
            
            <ul className="privacy-policy-list">
              <li>내부 관리용 사용자 ID</li>
              <li>관리자 권한 여부</li>
            </ul>
            
            <Typography variant="h6" component="h3" className="privacy-policy-subtitle">
              3) 일정 관리를 위해 수집하는 정보
            </Typography>
            
            <ul className="privacy-policy-list">
              <li>업로드된 엑셀 파일명</li>
              <li>업로드 날짜 및 시간</li>
              <li>파일 내용 (암호화된 형태로 저장)</li>
            </ul>

            <Typography variant="h5" component="h2" className="privacy-policy-subtitle">
              2. 개인정보의 수집 및 이용목적
            </Typography>
            
            <ul className="privacy-policy-list">
              <li>회원 식별 및 가입의사 확인</li>
              <li>서비스 제공 및 관리 (일정 관리 기능 포함)</li>
              <li>신규 서비스 개발 및 맞춤형 서비스 제공</li>
              <li>회원 관리 및 서비스 이용에 대한 통계</li>
              <li>공지사항 및 중요 업데이트 안내</li>
              <li>불법적인 사용 및 부정 이용 방지</li>
            </ul>

            <Typography variant="h5" component="h2" className="privacy-policy-subtitle">
              3. 개인정보의 보유 및 이용 기간
            </Typography>
            
            <Typography variant="body1" className="privacy-policy-text">
              회사는 이용자의 개인정보를 원칙적으로 개인정보의 수집 및 이용목적이 달성되면 지체 없이 파기합니다. 단, 관계법령의 규정에 의하여 보존할 필요가 있는 경우 회사는 아래와 같이 관계법령에서 정한 일정한 기간 동안 회원정보를 보관합니다.
            </Typography>
            
            <ul className="privacy-policy-list">
              <li>계약 또는 청약철회 등에 관한 기록: 5년</li>
              <li>대금결제 및 재화 등의 공급에 관한 기록: 5년</li>
              <li>소비자의 불만 또는 분쟁처리에 관한 기록: 3년</li>
              <li>일정 관리를 위해 업로드된 파일: 서비스 이용 종료 시 또는 회원 탈퇴 시까지</li>
            </ul>

            <Typography variant="h5" component="h2" className="privacy-policy-subtitle">
              4. 개인정보의 파기절차 및 방법
            </Typography>
            
            <Typography variant="body1" className="privacy-policy-text">
              회사는 원칙적으로 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 지체 없이 파기합니다. 파기절차 및 방법은 다음과 같습니다.
            </Typography>
            
            <ul className="privacy-policy-list">
              <li>파기절차: 이용자가 서비스 가입 등을 위해 입력한 정보는 목적이 달성된 후 별도의 DB로 옮겨져 내부 방침 및 기타 관련 법령에 의한 정보보호 사유에 따라 일정 기간 저장된 후 파기됩니다.</li>
              <li>파기방법: 전자적 파일 형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다. 업로드된 엑셀 파일의 경우, 회원 탈퇴 시 즉시 삭제됩니다.</li>
            </ul>

            <Typography variant="h5" component="h2" className="privacy-policy-subtitle">
              5. 개인정보의 제3자 제공
            </Typography>
            
            <Typography variant="body1" className="privacy-policy-text">
              회사는 이용자의 개인정보를 원칙적으로 외부에 제공하지 않습니다. 다만, 아래의 경우에는 예외로 합니다.
            </Typography>
            
            <ul className="privacy-policy-list">
              <li>이용자들이 사전에 동의한 경우</li>
              <li>법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우</li>
            </ul>
            
            <Typography variant="body1" className="privacy-policy-text">
              회사는 Google로부터 제공받은 개인정보를 Google을 포함한 제3자에게 제공하지 않습니다.
            </Typography>

            <Typography variant="h5" component="h2" className="privacy-policy-subtitle">
              6. 이용자 및 법정대리인의 권리와 그 행사방법
            </Typography>
            
            <ul className="privacy-policy-list">
              <li>이용자는 언제든지 등록되어 있는 자신의 개인정보를 조회하거나 수정할 수 있으며 가입해지를 요청할 수도 있습니다.</li>
              <li>이용자의 개인정보 조회, 수정을 위해서는 '개인정보변경'(또는 '회원정보수정' 등)을, 가입해지(동의철회)를 위해서는 "회원탈퇴"를 클릭하여 본인 확인 절차를 거치신 후 직접 열람, 정정 또는 탈퇴가 가능합니다.</li>
              <li>혹은 개인정보관리책임자에게 서면, 전화 또는 이메일로 연락하시면 지체 없이 조치하겠습니다.</li>
              <li>이용자가 개인정보의 오류에 대한 정정을 요청하신 경우에는 정정을 완료하기 전까지 당해 개인정보를 이용 또는 제공하지 않습니다.</li>
            </ul>

            <Typography variant="h5" component="h2" className="privacy-policy-subtitle">
              7. 개인정보 자동 수집 장치의 설치/운영 및 거부에 관한 사항
            </Typography>
            
            <Typography variant="body1" className="privacy-policy-text">
              회사는 이용자에게 개별적인 맞춤서비스를 제공하기 위해 이용정보를 저장하고 수시로 불러오는 '쿠키(cookie)'를 사용합니다. 쿠키는 웹사이트를 운영하는데 이용되는 서버가 이용자의 브라우저에게 보내는 아주 작은 텍스트 파일로 이용자 컴퓨터의 하드디스크에 저장됩니다.
            </Typography>
            
            <Typography variant="body1" className="privacy-policy-text">
              이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서 이용자는 웹브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도 있습니다.
            </Typography>

            <Typography variant="h5" component="h2" className="privacy-policy-subtitle">
              8. 개인정보의 안전성 확보 조치
            </Typography>
            
            <Typography variant="body1" className="privacy-policy-text">
              회사는 개인정보보호법 제29조에 따라 다음과 같이 안전성 확보에 필요한 기술적/관리적 및 물리적 조치를 하고 있습니다.
            </Typography>
            
            <ol className="privacy-policy-list">
              <li>개인정보 암호화: 이용자의 개인정보는 비밀번호와 같이 민감한 정보는 암호화되어 저장 및 관리되고 있어, 본인만이 알 수 있으며 중요한 데이터는 파일 및 전송 데이터를 암호화하거나 파일 잠금 기능을 사용하는 등의 별도 보안기능을 사용하고 있습니다.</li>
              <li>해킹 등에 대비한 기술적 대책: 회사는 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위하여 보안프로그램을 설치하고 주기적인 갱신·점검을 하며 외부로부터 접근이 통제된 구역에 시스템을 설치하고 기술적/물리적으로 감시 및 차단하고 있습니다.</li>
              <li>개인정보에 대한 접근 제한: 개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의 부여, 변경, 말소를 통하여 개인정보에 대한 접근통제를 위하여 필요한 조치를 하고 있으며 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있습니다.</li>
              <li>개인정보 취급 직원의 최소화 및 교육: 개인정보를 취급하는 직원을 지정하고 담당자에 한정시켜 최소화 하여 개인정보를 관리하는 대책을 시행하고 있습니다.</li>
            </ol>

            <Typography variant="h5" component="h2" className="privacy-policy-subtitle">
              9. 개인정보 보호책임자
            </Typography>
            
            <Typography variant="body1" className="privacy-policy-text">
              회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.
            </Typography>
            
            <Typography variant="body1" className="privacy-policy-text">
              <Typography variant="h6" component="h3">개인정보 보호책임자</Typography>
              <Typography variant="body1">성명: 양선모</Typography>
              <Typography variant="body1">직책: 책임</Typography>
              <Typography variant="body1">연락처: crewcreww.world@gmail.com (개인정보보호 담당자 이메일)</Typography>
                    
            
              이용자는 회사의 서비스를 이용하시면서 발생한 모든 개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보 보호책임자 또는 담당부서로 문의하실 수 있습니다. 회사는 이용자의 문의에 대해 지체 없이 답변 및 처리해드릴 것입니다.
            </Typography>

            <Typography variant="h5" component="h2" className="privacy-policy-subtitle">
              10. 개인정보 관련 문의 및 불만처리
            </Typography>
            
            <Typography variant="body1" className="privacy-policy-text">
              개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보 보호책임자 및 담당부서로 문의하실 수 있습니다.
            </Typography>
            
            <Typography variant="body1" className="privacy-policy-text">
              이메일: crewcrew.world@gmail.com
            </Typography>
            
            <Typography variant="body1" className="privacy-policy-text">
              또한, 개인정보가 침해되어 이에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하실 수 있습니다.
            </Typography>
            
            <ul className="privacy-policy-list">
              <li>개인정보침해신고센터 (privacy.kisa.or.kr / 국번없이 118)</li>
              <li>대검찰청 사이버수사과 (www.spo.go.kr / 국번없이 1301)</li>
              <li>경찰청 사이버안전국 (cyberbureau.police.go.kr / 국번없이 182)</li>
            </ul>

            <Typography variant="h5" component="h2" className="privacy-policy-subtitle">
              11. Google 계정 연동 관련 고지사항
            </Typography>
            
            <Typography variant="body1" className="privacy-policy-text">
              회사는 Google 로그인 기능을 통해 이용자의 Google 계정 정보를 수집합니다. 이용자는 Google 계정 설정을 통해 회사에 제공되는 정보를 제어할 수 있습니다. Google의 개인정보 처리방침은 Google 자체의 정책에 따르며, 자세한 내용은 Google의 개인정보 처리방침을 참조하시기 바랍니다.
            </Typography>

            <Typography variant="h5" component="h2" className="privacy-policy-subtitle">
              12. 이용자의 권리와 그 행사 방법
            </Typography>
            
            <Typography variant="body1" className="privacy-policy-text">
              이용자는 개인정보주체로서 다음과 같은 권리를 행사할 수 있습니다:
            </Typography>
            
            <ul className="privacy-policy-list">
              <li>개인정보 열람 요구</li>
              <li>오류 등이 있을 경우 정정 요구</li>
              <li>삭제 요구</li>
              <li>처리 정지 요구</li>
            </ul>
            
            <Typography variant="body1" className="privacy-policy-text">
              위 권리 행사는 회사에 대해 서면, 전화, 전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며 회사는 이에 대해 지체 없이 조치하겠습니다.
            </Typography>

            <Typography variant="h5" component="h2" className="privacy-policy-subtitle">
              13. 개인정보의 안전성 확보 조치
            </Typography>
            
            <Typography variant="body1" className="privacy-policy-text">
              회사는 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다:
            </Typography>
            
            <ul className="privacy-policy-list">
              <li>관리적 조치: 내부관리계획 수립 및 시행, 정기적 직원 교육 등</li>
              <li>기술적 조치: 개인정보처리시스템 등의 접근권한 관리, 접근통제시스템 설치, 고유식별정보 등의 암호화, 보안프로그램 설치</li>
              <li>물리적 조치: 전산실, 자료보관실 등의 접근통제</li>
            </ul>

            <Typography variant="h5" component="h2" className="privacy-policy-subtitle">
              14. 개정 이력
            </Typography>
            
            <ul className="privacy-policy-list">
              <li>2024년 08월 01일 (최초 제정)</li>
            </ul>

            <Typography variant="h5" component="h2" className="privacy-policy-subtitle">
              15. 고지의 의무
            </Typography>
            
            <Typography variant="body1" className="privacy-policy-text">
              현 개인정보처리방침 내용 추가, 삭제 및 수정이 있을 시에는 개정 최소 7일전부터 홈페이지의 '공지사항'을 통해 고지할 것입니다. 다만, 개인정보의 수집 및 활용, 제3자 제공 등과 같이 이용자 권리의 중요한 변경이 있을 경우에는 최소 30일 전에 고지합니다.
            </Typography>
          </div>
        </div>
      </Box>
    </ThemeProvider>
  );
};

export default PrivacyPolicy;